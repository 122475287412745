import React, { useEffect, useState } from "react";
import { Input, AutoComplete } from "antd";
import { useHistory } from "react-router-dom";

// components
import SearchResult from "./SearchResult";

// helpers
import { fullyDecodeURL } from "../../../helpers";

// hooks
import useQueryParams from "../../../hooks/useQueryParams";
import useDebounce from "../../../hooks/useDebounce";
import useAlgoliaBulkSearch, {
  buildSearchQuery,
} from "../../../hooks/useAlgoliaBulkSearch";

const formatSearchResults = (results) => {
  const { Item_query_suggestions } = results;

  const { Item, Category, Period, CatalogElement, Artisan, Dealer } = results;

  const materialsList =
    CatalogElement?.hits?.filter(
      ({ catalog_title }) => catalog_title === "Material"
    ) || [];

  const originsList =
    CatalogElement?.hits?.filter(
      ({ catalog_title }) => catalog_title === "Origin"
    ) || [];

  const searchResults = [];

  const orderedSearchResults = {
    Suggestion: Item_query_suggestions,
    Category,
    Period,
    Material: {
      hits: materialsList.map((item) => ({
        ...item,
        searchType: "Material",
      })),
    },
    Origin: {
      hits: originsList.map((item) => ({ ...item, searchType: "Origin" })),
    },
    Artisan,
    Dealer,
    Item,
  };

  Object.keys(orderedSearchResults).forEach((key) => {
    if (orderedSearchResults[key]) {
      const resultTypeList =
        orderedSearchResults[key].hits?.map((item) => {
          return {
            ...item,
            searchType: key,
          };
        }) ?? [];

      searchResults.push(...resultTypeList);
    }
  });

  return searchResults;
};

const MainSearch = () => {
  const history = useHistory();
  const [{ search = "" }, setQueryParams] = useQueryParams();

  const [searchValue, setSearchValue] = useState(fullyDecodeURL(search));
  const [searchOptions, setSearchOptions] = useState([]);

  const { results, fetch: fetchSearchResults } = useAlgoliaBulkSearch({
    queries: buildSearchQuery(""),
  });

  const debouncedSearch = useDebounce(searchValue, 500);

  // effects

  // Update search query when search value changes
  useEffect(() => {
    if (debouncedSearch) {
      const encodedValueForUrl = encodeURIComponent(
        fullyDecodeURL(searchValue)
      );

      setQueryParams(
        { search: encodedValueForUrl, page: 1, title: null },
        true
      );
    }
  }, [debouncedSearch]);

  // Fetch search results when search query changes
  useEffect(() => {
    if (search) {
      const searchQuery = fullyDecodeURL(search);
      const buildedSearchQuery = buildSearchQuery(searchQuery);

      fetchSearchResults(buildedSearchQuery);
    }
  }, [search]);

  // Update search options when search results change
  useEffect(() => {
    if (results) {
      const searchResults = formatSearchResults(results);

      setSearchOptions(searchResults);
    }
  }, [results]);

  // methods

  const onHandleKeyDown = (e) => {
    if (e.key !== "Enter") {
      return;
    }

    history.push({
      pathname: search ? "/search-results" : "/antiques",
      ...(search && { search: `?search=${search}` }),
    });
  };

  const onHandleChange = (value) => {
    const newSearchValue = fullyDecodeURL(value);

    setSearchValue(newSearchValue);
  }

  // render

  const showMoreOption = {
    key: "more",
    query: search,
    searchType: "More",
    title: "Show more results...",
  };

  return (
    <AutoComplete
      className="header--search"
      value={searchValue}
      onChange={onHandleChange}
      onKeyDown={onHandleKeyDown}
      role="combobox"
      type="search"
      aria-label="Search"
      aria-controls="search-results"
      aria-expanded={!!searchValue}
      aria-haspopup="listbox"
      aria-autocomplete="list"
      options={
        searchValue
          ? [showMoreOption, ...searchOptions].map((searchResult, index) => {
            const isSimpleOption = ["Suggestion", "More"].includes(
              searchResult.searchType
            );

            return {
              key: index,
              label: <SearchResult searchResult={searchResult} />,
              ...(isSimpleOption && {
                value: searchResult.query,
              }),
            };
          })
          : []
      }
    >
      <Input.Search
        size="large"
        placeholder="Search for Items, Period, Artisans"
      />
    </AutoComplete>
  );
};

export default MainSearch;
